import objectKeysToCamelCase from '@grebban/utils/object/keysToCamelCase';
import { createSlice, current } from '@reduxjs/toolkit';
import resolveApplication from '@sportson/core-web/state/application/resolve';

export interface IStore {
    id: string;
    storeId: number;
    name: string;
    slug: string;
    storeVisible: boolean;
    information: {
        email: string;
        secondaryEmail: string;
    };
    contact: {
        email: string | null;
    } | null;
    norceStore: {
        latitude: string;
        longitude: string;
        address: string;
        postalAddress: string;
        phone: string;
        email: string | null;
    };
    unavailableManufacturers: {
        manufacturer: {
            id: number;
            code: string;
            name: string;
        }[];
    };
    on_hand_value: number;
}
export interface IOverlay {
    selected: IStore | null;
    stores: IStore[];
}

export type IStoresState = IOverlay;

const initialState: IStoresState = {
    selected: null,
    stores: [],
};

const slice = createSlice({
    name: 'stores',
    initialState,
    reducers: {
        setStore(state, action) {
            const selectedStore = state.stores.find((s) => s.storeId === action.payload) || null;
            state.selected = selectedStore;
            localStorage.setItem('sportson_selected_store', action.payload);
        },
        setInitialStore(state, action) {
            const initialStore = current(state).stores.find((x) => x.storeId === Number(action.payload)) || null;
            state.selected = initialStore;
        },
        clearStore(state) {
            state.selected = initialState.selected;
            localStorage.removeItem('sportson_selected_store');
        },
    },
    extraReducers(builder) {
        builder.addCase(resolveApplication.fulfilled, (state, action) => ({
            ...state,
            stores: objectKeysToCamelCase(action.payload?.data?.stores || {}, {
                recursive: true,
                modifyInputObject: false,
            }),
        }));
    },
});

export {};
export const { setStore, setInitialStore, clearStore } = slice.actions;
export const { reducer } = slice;
