import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import useBreakpoint from '@sportson/core-web/hooks/useBreakpoint';
import Events, { EVENTS } from '@sportson/core-web/config/events';
import useAppSelector from '@sportson/core-web/hooks/useAppSelector';
import { debugHelper } from '@sportson/core-web/utils/debugHelper';

type ReEvaluateHeaderStateParameter = {
    headerState?: HeaderContextState;
    withStateChanges?: Partial<HeaderContextState>;
};

type ReEvaluateHeaderState = (parameter?: ReEvaluateHeaderStateParameter) => void;

export type HeaderContextState = typeof initialHeaderContextState;

export type HeaderContext = typeof initialHeaderContext;

const setHeaderCssVars = (state: HeaderContextState, device: 'mobile' | 'desktop') => {
    const rootElement = document.querySelector(':root');
    if (!rootElement) {
        return;
    }
    const { endConsoleGroup } = debugHelper({
        context: 'HeaderContext',
        message: 'setHeaderCssVars:init',
        options: {
            createConsoleMessageGroup: { label: 'setHeaderCssVars' },
        },
        additionalParameters: {
            state,
            device,
        },
    });

    const updates: string[] = [];

    [
        // {property: "", value: ""},
        { property: '--banner-height', value: `${state.banner.height[device]}px` },
        { property: '--header-height', value: `${state.height[device]}px` },
        { property: '--header-height-total', value: `${state.height[device] + state.banner.height[device]}px` },
        {
            property: '--header-top-position',
            value: state.isShowing ? '0' : `-${state.height[device] + state.banner.height[device]}px`,
        },
        {
            property: '--header-text-color',
            value: state.isTransparent ? 'var(--color-base-white)' : 'var(--header-text-color-default)',
        },
        {
            property: '--header-background-color',
            value: state.isTransparent ? 'var(--color-transparent)' : 'var(--header-background-color-default)',
        },
    ].forEach(({ property, value }) => {
        // @ts-expect-error: Reason:  We know that style exists.
        const currentValue = rootElement.style.getPropertyValue(property);
        if (currentValue !== value) {
            // @ts-expect-error: Reason:  We know that style exists.
            rootElement.style.setProperty(property, value);
            updates.push(`${property}: ${currentValue} => ${value}`);

            debugHelper({
                context: 'HeaderContext',
                message: `setHeaderCssVars:property > ${property}`,
                additionalParameters: {
                    currentValue,
                    newValue: value,
                },
            });
        }
    });

    debugHelper({
        context: 'HeaderContext',
        message: `setHeaderCssVars:complete`,
        additionalParameters: { updates },
    });

    endConsoleGroup?.();
};

const HEADER_OPTIONS = {
    SCROLL_DEBOUNCE_TIMER: 300,
    TRANSPARENT_HEADER_VIEWS: {
        article: ['article_collection'],
        page: ['not_found'],
        centra_checkout: ['default'],
    },
};

const HEADER_OVERRIDES: Record<string, Partial<HeaderContextState>> = {};

const initialHeaderContextState = {
    isTransparent: false, // If there is something incorrectly happening, it is better if the header is not transparent since it would render fine visually for the customer.
    isShowing: true, // isVisible: true, // Swap to

    height: {
        mobile: 112, // Set the initial value as close as possible to get correct rendering from start,
        desktop: 128, // Set the initial value as close as possible to get correct rendering from start,
    },

    banner: {
        height: {
            mobile: 28, // Set the initial value as close as possible to get correct rendering from start,
            desktop: 32, // Set the initial value as close as possible to get correct rendering from start,
        },
    },

    // Use this instead?
    // navigation: {
    //     menuIsShowing: false,
    // },
    // search: {
    //     isVisible: true,
    // },
    // basket: {
    //     isVisible: true,
    // },
    // refs: {
    //     header: null,
    // },
};

const initialHeaderContext = {
    headerState: initialHeaderContextState,
    setOverride: (context: string, stateReplacement?: Partial<HeaderContextState>) => {},
    reEvaluateHeaderState: (parameter: ReEvaluateHeaderStateParameter) => {},
};

const HeaderContext = createContext(initialHeaderContext);
export const useHeaderContext = () => useContext(HeaderContext);

interface HeaderContextProviderProps {
    children: React.ReactNode;
}

export const HeaderContextProvider = ({ children }: HeaderContextProviderProps) => {
    const pageType = useAppSelector(({ page }) => page?.type ?? '');
    const pageTemplate = useAppSelector(({ page }) => page?.template);
    const overlayActive = !!useAppSelector(({ application }) => application.overlay.view || ''); // @TODO Move to header overlay?
    const isMobile = useBreakpoint({ to: 'tablet.sm' });
    const currentDevice = isMobile ? 'mobile' : 'desktop';
    const prevScrollRef = useRef(0);
    const bannerText: string = useAppSelector(({ application }) => application.header.data.banner?.message?.text);
    const bannerVisible = !!bannerText;

    // Initialize state with correct banner height based on bannerVisible
    const [state, setState] = useState({
        ...initialHeaderContextState,
        banner: {
            height: bannerVisible ? initialHeaderContextState.banner.height : { mobile: 0, desktop: 0 },
        },
    });

    // @TODO Make this a useCallback?
    const reEvaluateHeaderState: ReEvaluateHeaderState = useCallback(
        ({ headerState = state, withStateChanges = {} } = {}) => {
            const { endConsoleGroup } = debugHelper({
                context: 'HeaderContext',
                message: 'reEvaluateHeaderState:Init',
                options: {
                    createConsoleMessageGroup: {
                        label: 'reEvaluateHeaderState',
                    },
                },
                additionalParameters: {
                    currentState: state,
                    evaluationState: headerState,
                    withStateChanges,
                    currentDevice,
                    HEADER_OVERRIDES,
                },
            });

            const currentScrollYOffset = window?.scrollY || 0;

            let newState = {
                ...headerState,
                isTransparent:
                    currentScrollYOffset <= headerState.height[currentDevice] &&
                    !!HEADER_OPTIONS.TRANSPARENT_HEADER_VIEWS?.[pageType]?.includes(pageTemplate),
                isShowing:
                    currentScrollYOffset < headerState.height[currentDevice] ||
                    currentScrollYOffset <= prevScrollRef.current,
                ...withStateChanges,
            };
            debugHelper({
                context: 'HeaderContext',
                message: `reEvaluateHeaderState:evaluation`,
                additionalParameters: {
                    isTransparent: {
                        currentScrollYOffset,
                        headerHeight: headerState.height[currentDevice],
                        isTransparentView:
                            !!HEADER_OPTIONS.TRANSPARENT_HEADER_VIEWS?.[pageType]?.includes(pageTemplate),
                        pageType,
                        pageTemplate,
                        transparentViews: HEADER_OPTIONS.TRANSPARENT_HEADER_VIEWS,
                    },
                    isShowing: {
                        currentScrollYOffset,
                        headerHeight: headerState.height[currentDevice],
                        oldScrollPos: prevScrollRef.current,
                    },
                    newState,
                },
            });

            // Run the overrides from other sources.
            Object.keys(HEADER_OVERRIDES).forEach((overrideContext) => {
                const overrideContextState = HEADER_OVERRIDES[overrideContext];
                newState = {
                    ...newState,
                    ...overrideContextState,
                };
                debugHelper({
                    context: 'HeaderContext',
                    message: `reEvaluateHeaderState:evaluationOverrides`,
                    additionalParameters: {
                        newState,
                        overrideContextState,
                        overrideContext,
                    },
                });
            });

            const hasChanges = JSON.stringify(newState) !== JSON.stringify(state); // @TODO Improve this with something else than json compare?

            debugHelper({
                context: 'HeaderContext',
                message: `reEvaluateHeaderState:State Differences? (${hasChanges.toString()})`,
                additionalParameters: {
                    headerState,
                    newState,
                },
            });

            // Don't set a new state if nothing has changed since it would trigger a re-render.
            if (hasChanges) {
                debugHelper({
                    context: 'HeaderContext',
                    message: `reEvaluateHeaderState:setState`,
                    additionalParameters: newState,
                });
                setState(newState);
            }

            endConsoleGroup?.();
        },
        [state, pageType, pageTemplate, prevScrollRef.current],
    );

    const setOverride = useCallback(
        (context: string, stateReplacement?: Partial<HeaderContextState>, evaluateState = true) => {
            const { endConsoleGroup } = debugHelper({
                context: 'HeaderContext',
                message: 'setOverride:Init',
                options: {
                    createConsoleMessageGroup: { label: 'setOverride' },
                },
                additionalParameters: {
                    context,
                    stateReplacement,
                    evaluateState,
                    HEADER_OVERRIDES,
                },
            });

            if (stateReplacement) {
                HEADER_OVERRIDES[context] = stateReplacement;
                debugHelper({
                    context: 'HeaderContext',
                    message: `setOverride:Added ${context}`,
                    additionalParameters: stateReplacement,
                });
            } else {
                delete HEADER_OVERRIDES[context];
                debugHelper({
                    context: 'HeaderContext',
                    message: `setOverride:Removed ${context}`,
                });
            }

            if (evaluateState) {
                reEvaluateHeaderState();
            }

            endConsoleGroup?.();
        },
        [state, pageType, pageTemplate, prevScrollRef.current, reEvaluateHeaderState],
    );

    let scrollDebounceHandle: ReturnType<typeof setTimeout> | undefined;
    useEffect(() => {
        const { endConsoleGroup } = debugHelper({
            context: 'HeaderContext',
            message: 'onMount:init',
            options: {
                createConsoleMessageGroup: { label: 'onMount' },
            },
        });

        // @TODO if the height changes, re-set the height
        // const resizeEventHandle = Events.subscribe(
        //     EVENTS.WINDOW.RESIZE,
        //     debounce((ev) => {
        //         // setHeaderCssVars(state.height[currentDevice], state.banner.height[currentDevice]);
        //         console.log("resize", { state });
        //     }, state.debounce),
        // );

        // @TODO We dont need to evaluate every scroll event, we should evaluate after X pixels.
        const scrollEventHandle = Events.subscribe(EVENTS.WINDOW.SCROLL, () => {
            (async () => {
                if (!scrollDebounceHandle) {
                    debugHelper({
                        context: 'HeaderContext',
                        message: 'scrollEvaluation:init',
                    });
                }

                // Clear the earlier timeout if we have one, we will create a new one.
                if (scrollDebounceHandle) {
                    clearTimeout(scrollDebounceHandle);
                }

                // Set up the debounce timer variable
                let debounceTimer = HEADER_OPTIONS.SCROLL_DEBOUNCE_TIMER;

                // Skip the timer:
                // If we are close to the top
                // If we are scrolling down and the header is visible
                // If we are scrolling up and the header is invisible
                const currentScrollYOffset = window?.scrollY || 0;
                if (
                    currentScrollYOffset <= state.height[currentDevice] ||
                    (currentScrollYOffset > prevScrollRef.current && state.isShowing) ||
                    (currentScrollYOffset < prevScrollRef.current && !state.isShowing)
                ) {
                    debounceTimer = 1;
                }

                scrollDebounceHandle = setTimeout(() => {
                    const currentScrollYOffset = window?.scrollY || 0;
                    const { endConsoleGroup } = debugHelper({
                        context: 'HeaderContext',
                        message: 'scrollEvaluation:debounceCallback:run',
                        options: {
                            createConsoleMessageGroup: {
                                label: 'scrollEvaluation:debounceCallback',
                            },
                        },
                    });

                    reEvaluateHeaderState();

                    debugHelper({
                        context: 'HeaderContext',
                        message: `scrollEvaluation:setNewScrollPosition (${prevScrollRef.current} => ${currentScrollYOffset})`,
                    });
                    prevScrollRef.current = currentScrollYOffset;
                    scrollDebounceHandle = undefined;

                    endConsoleGroup?.();
                }, debounceTimer);
            })();
        });

        // Ends the onMount logs.
        endConsoleGroup?.();

        return () => {
            const { endConsoleGroup } = debugHelper({
                context: 'HeaderContext',
                message: 'unMount:init',
                options: {
                    createConsoleMessageGroup: { label: 'unMount' },
                },
            });

            // Events.unsubscribe(EVENTS.WINDOW.RESIZE, resizeEventHandle);
            Events.unsubscribe(EVENTS.WINDOW.SCROLL, scrollEventHandle);

            // Ends the unMount logs
            endConsoleGroup?.();
        };
    }); // @TODO Add deps and add re-eval? Right now this triggers extra re-renders, but the functions are not updated correctly if so.

    // If a view is updated, we need to re-evaluate the header
    useEffect(() => {
        debugHelper({ context: 'HeaderContext', message: 'view changes' });
        reEvaluateHeaderState();
    }, [pageType, pageTemplate]);

    // If the device/responsiveness is updated, we need to set the header variables and re-evaluate the header
    useEffect(() => {
        const { endConsoleGroup } = debugHelper({
            context: 'HeaderContext',
            message: 'stateChange:init',
            additionalParameters: state,
            options: {
                createConsoleMessageGroup: {
                    label: 'stateChange',
                },
            },
        });
        setHeaderCssVars(state, currentDevice);
        endConsoleGroup?.();
    }, [state]);

    // If the device/responsiveness is updated, we need to set the header variables and re-evaluate the header
    useEffect(() => {
        const { endConsoleGroup } = debugHelper({
            context: 'HeaderContext',
            message: 'stateChange:init',
            additionalParameters: state,
            options: {
                createConsoleMessageGroup: {
                    label: 'stateChange',
                },
            },
        });
        setHeaderCssVars(state, currentDevice);
        endConsoleGroup?.();
    }, [state]);

    debugHelper({
        context: 'HeaderContext',
        message: 'Render',
        additionalParameters: {
            state,
            pageType,
            pageTemplate,
            overlayActive,
            isMobile,
            options: HEADER_OPTIONS,
            overrides: HEADER_OVERRIDES,
        },
    });

    // const headerContextProviderProps = useMemo<HeaderContext>(
    //     () => ({
    //         headerState: state,
    //         reEvaluateHeaderState,
    //         setOverride,
    //     }),
    //     [state],
    // );
    const headerContextProviderProps = {
        headerState: state,
        reEvaluateHeaderState,
        setOverride,
    };

    return <HeaderContext.Provider value={headerContextProviderProps}>{children}</HeaderContext.Provider>;
};

export const useOverrideHeaderContext = (context: string, override: Partial<HeaderContextState>) => {
    const { setOverride } = useHeaderContext();
    useEffect(() => {
        setOverride(context, override);

        return () => {
            setOverride(context);
        };
    }, []);
};
