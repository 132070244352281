import { del } from '@sportson/core-web/libs/GrebbCommerceAPI/util';
import { getModel } from '@sportson/core-web/state';

export default async (basketId, paymentCode) => {
    const { applicationId } = getModel('application').site;

    const data = {
        application_id: applicationId,
        payment_parameters: [
            {
                Name: 'paymentcode',
                Value: paymentCode,
            },
            {
                Name: 'PaymentService',
                Value: 'Retain24',
            },
        ],
    };

    const response = await del(`/frontend/basket/gift-card/${basketId}/reserve`, data);
    return await response.body();
};
