import mutex from '@grebban/mutex';
import objectKeysToCamelCase from '@grebban/utils/object/keysToCamelCase';
// import getBasketItemById from 'utils/basket/getBasketItemById';
import { createAsyncThunk } from '@reduxjs/toolkit';
import Events from '@sportson/core-web/libs/Events';
import UpdateBasketItem from '@sportson/core-web/libs/GrebbCommerceAPI/Basket/UpdateBasketItem';

import tracking from '@sportson/core-web/components/Tracking';
import { BasketEvents } from '@sportson/core-web/libs/Events/constants';
import getBasketId from '@sportson/core-web/state/ecommerce/norce/utils/getBasketId';
import getItemById from '@sportson/core-web/state/ecommerce/norce/utils/getItemById';
import getItemByPartNo from '@sportson/core-web/state/models/Basket/utils/getItemByPartNo';
import buildCartProductTree from '@sportson/core-web/utils/buildCartProductTree';
import getErrorMesage from '@sportson/core-web/utils/getErrorMessage';
import { handleVirtualBasket } from '@sportson/core-web/utils/virtualBasket';

export interface UpdateBasketItemData {
    basketItemId: string | number;
    // manualPrice: number;
    partNo: string | number;
    pricelistId?: string | null;
    quantity: number;
    source: string;
}

export default createAsyncThunk(
    `basket/product/update`,
    async (data: UpdateBasketItemData, { getState, dispatch, rejectWithValue, fulfillWithValue }) => {
        const basketId = getBasketId();

        if (!basketId) {
            return rejectWithValue({
                error: 'BasketId is not set.',
            });
        }

        const mutexLock = await mutex('basket');

        try {
            const { basketItemId, partNo, pricelistId, quantity, source } = data;

            const { checkout, basket, stores } = getState().ecommerce;
            const { currency } = getState().application.site;
            // todo: deep clone?
            const trackedItem = { ...(getItemById(basketItemId, basket.items) || {}) };
            if (source === 'remove') {
                trackedItem.quantity -= quantity;
            }
            if (source === 'add') {
                trackedItem.quantity = quantity - trackedItem.quantity;
            }

            const eventId = tracking.getUniqueId();
            const response = await UpdateBasketItem(basketId, partNo, basketItemId, quantity, pricelistId, eventId);

            const items = response.data.items || [];

            try {
                const event = source === 'add' ? BasketEvents.PRODUCT_ADDED : BasketEvents.PRODUCT_REMOVED;

                Events.trigger(event, {
                    basketId: basketId || '',
                    item: { ...getItemByPartNo(partNo, items) },
                    eventId,
                    currency: currency.code || 'SEK',
                });
            } catch (e) {
                //
            }

            handleVirtualBasket(basket.id, items, basket.items, stores.selected, dispatch, checkout.payments);
            mutexLock();
            return fulfillWithValue({
                ...response.data,
                source,
                eventId,
                trackedItem,
                items: buildCartProductTree(items),
            });
        } catch (error: unknown) {
            mutexLock();
            return rejectWithValue({ error: getErrorMesage(error) });
        }
    },
);

const pending = (state, action) => {
    state.status = 'pending';
};

const fulfilled = (state, action) => {
    const camelizedData = objectKeysToCamelCase(action.payload, {
        recursive: true,
        modifyInputObject: false,
    });

    return {
        ...state,
        ...camelizedData,
        status: 'idle',
    };
};

const rejected = (state, action) => {
    console.error(action.payload);
    return {
        ...state,
        status: 'idle',
    };
};

export const updateBasketItemStateCallbacks = {
    pending,
    fulfilled,
    rejected,
};
