import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAppDispatch from '@sportson/core-web/hooks/useAppDispatch';
import useAppSelector from '@sportson/core-web/hooks/useAppSelector';
import useBreakpoint from '@sportson/core-web/hooks/useBreakpoint';
import useDebounce from '@sportson/core-web/hooks/useDebounce';
import { close as closeOverlay, show as showOverlay } from '@sportson/core-web/state/application/overlay';
import { updateSearch } from '@sportson/core-web/state/page/';
import { above, media } from '@sportson/core-web/utils/mediaqueries';
import styleObjectToProps from '@sportson/core-web/utils/styleObjectToProps';
import Button from 'components/Button';
import styled from 'libs/styled';
import Text from 'components/Text';
import { SearchIcon } from 'assets/media/icons/SearchIcon';
import { ArrowIcon } from 'assets/media/icons/ArrowIcon';

const StyledForm = styled('form')`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    ${above['desktop.sm']} {
        max-width: 720px;
    }
`;

const SearchButton = styled(Button)``;

const IconWrapper = styled('div')`
    position: absolute;
    width: fit-content;
    left: 12px;
`;

const SearchInput = styled('input')`
    width: 100%;
    height: 40px;
    padding: 12px 12px 12px 36px;
    border: none;
    border-radius: 100px;
    font-family: 'Emeric';
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    border: 1px solid var(--color-neutrals-600);
    transition: all var(--transition-primary-fast);

    background-color: ${(props) => (props.hasQuery ? 'var(--color-base-white)' : 'var(--color-base-black)')};
    color: ${(props) => (props.hasQuery ? 'var(--color-base-black)' : 'var(--color-neutrals-400)')};

    &::placeholder {
        color: var(--color-neutrals-400);
    }

    &:focus {
        color: var(--color-base-black);

        &::placeholder {
            color: var(--color-base-black);
            opacity: 1;
        }
    }

    ${media.hover} {
        &:hover {
            border: 1px solid var(--color-transparency-grey-50);
            color: var(--color-base-black);
            background-color: ${(props) =>
                props.hasQuery ? 'var(--color-base-white)' : 'var(--color-transparency-grey-50)'};
        }
    }

    &:focus,
    &:focus-visible {
        // @TODO Feeling cute (and like something our designers talked abount), may be deleted later.
        // Actually they want outline when input is focused by tab, but not click. The :focus-visible in base css doesn't seem to do that for this input
        outline-offset: -1px;
        background-color: var(--color-base-white);
        outline: 1px solid var(--color-transparency-grey-50);
    }

    /* clears the 'X' from Chrome */
    ::-webkit-search-decoration,
    ::-webkit-search-cancel-button,
    ::-webkit-search-results-button,
    ::-webkit-search-results-decoration {
        display: none;
    }

    &[data-search-open='false'] {
        &::placeholder {
            opacity: 1;
        }
    }
`;

const SearchActionWrapper = styled('div')`
    display: none;
    position: absolute;
    top: 1px;
    right: 1px;
    height: calc(100% - 2px);
    flex-direction: row;
    gap: 16px;

    &[data-search-open='true'] {
        display: flex;
    }
`;

const ClearButton = styled('button')`
    color: var(--color-neutrals-400, #969696);
    text-decoration: underline;
    padding: 11px 8px;

    &[data-has-query='true'] {
        display: block;
    }
`;

// @TODO clean this up and maybe some clearer naming of some variables
// @TODO build redux for search like Zoo? seems deleted probably because Algolia vs Voyado situation. See reduxSearchState. But is that all we collect from there?

const SearchBar = ({ maxLength }) => {
    const [query, setQuery] = useState('');
    const isInitialMount = useRef(false);
    const inputRef = useRef();
    const history = useHistory();
    const searchPath = useAppSelector(
        ({ application }) => application?.config?.options?.specifiedPages?.specifiedPages?.search,
    );
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const isAboveDesktop = useBreakpoint({ from: 'desktop.sm' });

    const { view } = useAppSelector(({ application }) => application.overlay);
    const placeholderText =
        useAppSelector(({ application }) => application.header?.data?.search?.inputPlaceholderText) || '';
    const activeInputPlaceholderText =
        useAppSelector(({ application }) => application.header?.data?.search?.placeholderTextActiveState) ||
        placeholderText;

    const debouncedQuery = useDebounce(query, 350);
    const isSearchOpen = view === 'searchOverlay';
    const textStyles = styleObjectToProps('UI/16_100_0_350');

    const openOverlay = () => {
        const data = {};
        dispatch(showOverlay({ view: 'searchOverlay', transition: 'fade', data }));
        inputRef.current.focus();
    };

    const closeSearchOverlay = (e) => {
        e.stopPropagation();
        dispatch(closeOverlay());
    };

    const onSubmit = (e) => {
        e.preventDefault();

        const term = inputRef.current.value;
        const path = term?.length ? `${searchPath}?search=${term}` : searchPath;
        history.push(path);

        dispatch(updateSearch({ search: '' }));
        inputRef.current.blur();
        setQuery('');
        dispatch(closeOverlay());
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            // Enter
            onSubmit(e);
        }
        if (e.key === 'Escape') {
            // ESC
            e.target.blur();
            e.preventDefault();
            dispatch(closeOverlay());
        }
    };

    const clearSearch = () => {
        dispatch(closeOverlay());
        setQuery('');
    };

    const decodedQuery = (query) => {
        // TODO: should maybe not need to use replace here? But for MVP it's ok
        let decodedQuery = query.replace('?search=', '');
        try {
            decodedQuery = decodeURI(decodedQuery);
        } catch (e) {
            console.error(e);
        }
        return decodedQuery;
    };

    const handleWrapperClick = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
        if (!isSearchOpen) {
            openOverlay();
        }
    };

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            dispatch(updateSearch({ search: query }));
        }
    }, [debouncedQuery]);

    // cmd + k opens searchbar
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.metaKey && (event.key === 'K' || event.key === 'k')) {
                event.preventDefault();
                openOverlay();
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <StyledForm {...textStyles} onSubmit={onSubmit}>
            <IconWrapper>
                <SearchIcon width="16px" height="16px" fill="var(--color-neutrals-400)" />
            </IconWrapper>
            <SearchInput
                id="searchField"
                type="search"
                ref={inputRef}
                // Sets correct action label on virtual keyboards.
                enterKeyHint="search"
                placeholder={isSearchOpen ? activeInputPlaceholderText : placeholderText}
                maxLength={maxLength}
                autoComplete="off"
                value={decodedQuery(query)}
                onChange={(e) => setQuery(e.target.value.replace(/^\s+/, ''))}
                onKeyDown={handleKeyPress}
                onFocus={openOverlay}
                data-search-open={isSearchOpen}
                onClick={handleWrapperClick}
                hasQuery={query.length > 0}
                isSearchOpen={isSearchOpen}
                aria-label={t('search.placeholder')}
            />
            <SearchActionWrapper data-search-open={(isSearchOpen && isAboveDesktop) || query.length > 0}>
                <ClearButton
                    type="button"
                    aria-label={t('search.clear')}
                    data-has-query={query.length > 0}
                    onClick={clearSearch}
                >
                    <Text typography="UI/16_100_0_450">{t('search.clear')}</Text>
                </ClearButton>

                <SearchButton type="submit" ariaLabel={t('search.search')}>
                    {t('search.search')} <ArrowIcon size="xsmall" />
                </SearchButton>
            </SearchActionWrapper>
        </StyledForm>
    );
};

SearchBar.propTypes = {
    maxLength: PropTypes.string,
    searchPath: PropTypes.string,
};

SearchBar.defaultProps = {
    maxLength: '50',
    searchPath: '',
};

export default SearchBar;
