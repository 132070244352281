import Events from '@sportson/core-web/libs/Events';
import { BasketEvents } from '@sportson/core-web/libs/Events/constants';
import RollbackGiftCard from '@sportson/core-web/libs/GrebbCommerceAPI/Basket/RollbackGiftCard';
import { acquireMutex } from '@sportson/core-web/state';
import {
    ROLLBACK_GIFT_CARD,
    ROLLBACK_GIFT_CARD_ERROR,
    ROLLBACK_GIFT_CARD_SUCCESS,
} from '@sportson/core-web/state/models/Basket/constants';
import store from '@sportson/core-web/state/store';

/**
 * Removes giftcard payment. Checks if there's a payment in the users basket in Norce with matching paymentcode
 *
 * @param {number} paymentCode an id for the specific giftcard payment
 * @returns response
 */

export const rollbackGiftCard = (paymentCode) => async (dispatch, getState) => {
    const { id } = getState().ecommerce.basket;
    const mutexLock = await acquireMutex('basket.getBasket');

    dispatch({ type: ROLLBACK_GIFT_CARD });
    try {
        const response = await RollbackGiftCard(id, paymentCode);

        dispatch({
            type: ROLLBACK_GIFT_CARD_SUCCESS,
        });

        Events.trigger(BasketEvents.GIFT_CARD_ROLLBACK);
        Events.trigger(BasketEvents.UPDATED);

        mutexLock();

        return response;
    } catch (e) {
        dispatch({ type: ROLLBACK_GIFT_CARD_ERROR });

        mutexLock();

        console.error(e);

        return null;
    }
};

export default (paymentCode) => store.dispatch(rollbackGiftCard(paymentCode));
