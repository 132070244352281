import mutex from '@grebban/mutex';
import objectKeysToCamelCase from '@grebban/utils/object/keysToCamelCase';

import { createAsyncThunk } from '@reduxjs/toolkit';
import AddToBasket from '@sportson/core-web/libs/GrebbCommerceAPI/Basket/AddToBasket';
import buildCartProductTree from '@sportson/core-web/utils/buildCartProductTree';
import getErrorMesage from '@sportson/core-web/utils/getErrorMessage';
import store from '@sportson/core-web/state/store';

import tracking from '@sportson/core-web/components/Tracking';
// import Events from '@sportson/core-web/libs/Events';
// import { BasketEvents } from '@sportson/core-web/libs/Events/constants';

import Events from '@sportson/core-web/libs/Events';
import { BasketEvents } from '@sportson/core-web/libs/Events/constants';
import getBasketId from '@sportson/core-web/state/ecommerce/norce/utils/getBasketId';
import getItemByPartNo from '@sportson/core-web/state/models/Basket/utils/getItemByPartNo';
import setBasketItemsAmountCookie from '@sportson/core-web/state/models/Basket/utils/setBasketItemsAmountCookie';
import { handleVirtualBasket } from '@sportson/core-web/utils/virtualBasket';

export interface AddProductToBasketData {
    partNo: string | number;
    quantity: number;
    pricelistId?: string | null;
    infos?: any[];
    comment?: string;
    manualPrice: number;
}

export default createAsyncThunk(
    `basket/product/add`,
    async (data: AddProductToBasketData, { getState, dispatch, rejectWithValue, fulfillWithValue }) => {
        const basketId = getBasketId();

        if (!basketId) {
            return rejectWithValue({
                error: 'BasketId is not set.',
            });
        }

        const { partNo, quantity = 1, pricelistId = null, infos = [], comment = '', manualPrice } = data;
        const { checkout, basket, stores } = store.getState().ecommerce;

        const mutexLock = await mutex('basket');
        try {
            const eventId = tracking.getUniqueId();
            const response = await AddToBasket(
                basketId,
                partNo,
                quantity,
                pricelistId,
                infos,
                comment,
                eventId,
                manualPrice,
            );

            const items = response.data.items || [];

            try {
                Events.trigger(BasketEvents.PRODUCT_ADDED, {
                    basketId,
                    item: { ...getItemByPartNo(partNo, items) },
                    eventId,
                });
            } catch (e) {
                //
            }

            const basketItems = response.data.items;
            const { id: basketItemId } = basketItems.find(({ part_no: itemPartNo }) => itemPartNo === partNo) ?? {};
            const trackingData = {
                basketId,
                item: { basketId, partNo, basketItemId, quantity, pricelistId, source: 'add' },
            };
            handleVirtualBasket(basket.id, items, basket.items, stores.selected, dispatch, checkout.payments);
            mutexLock();
            return fulfillWithValue({
                ...response.data,
                items: buildCartProductTree(response.data.items),
                trackingData,
            });
        } catch (error: unknown) {
            mutexLock();
            return rejectWithValue({ error: getErrorMesage(error) });
        }
    },
);

const pending = (state, action) => {
    state.status = 'pending';
};

const fulfilled = (state, action) => {
    setBasketItemsAmountCookie(action.payload.items);
    const camelizedData = objectKeysToCamelCase(action.payload, {
        recursive: true,
        modifyInputObject: false,
    });
    return {
        ...state,
        ...camelizedData,
        status: 'idle',
    };
};

const rejected = (state, action) => {
    console.error(action.payload);
    return {
        ...state,
        status: 'idle',
    };
};

export const addProductToBasketStateCallbacks = {
    pending,
    fulfilled,
    rejected,
};
