import styled from '@grebban/style-system-react';
import loadable from '@loadable/component';
import PageMeta from '@sportson/core-web/components/metadata/PageMeta';
import Events, { EVENTS } from '@sportson/core-web/config/events';
import useAppSelector from '@sportson/core-web/hooks/useAppSelector';
// Workaround, GTM events are registered on the core-web events package, not @grebban/events
import AppEvents from '@sportson/core-web/libs/Events';
import { PageEvents, ProductEvents } from '@sportson/core-web/libs/Events/constants';
import { debugHelper } from '@sportson/core-web/utils/debugHelper';
import { CompareProductsPopup } from 'components/CompareProducts/CompareProductsPopup';
import ContentWrapper from 'components/ContentWrapper';
import React, { useEffect, useMemo } from 'react';

const Main = styled('main')`
    position: relative;
    overflow: hidden;
`;

const StyledContentWrapper = styled(ContentWrapper)<{ customStyling: boolean }>`
    margin-top: ${({ customStyling }) =>
        customStyling ? undefined : 'calc(var(--header-height) + var(--banner-height))'};
    max-width: ${({ customStyling }) => (customStyling ? undefined : 'var(--site-corridor-max-width)')};
`;

export const viewsMapping = {
    page: {
        about_us: 'Views/AboutUs',
        default: 'Views/Page',
        frontpage: 'Views/Frontpage',
        not_found: 'Views/NotFound',
        bike_selector: 'Views/BikeSelector',
        search: 'Views/Search',
        store_listing: 'Views/StoreListing',
        compare: 'Views/CompareProducts',
        shared_basket: 'Views/SharedBasket',
    },
    customer_service: {
        customer_service_contact: 'Views/CustomerServiceContact',
        customer_service_faq: 'Views/CustomerServiceFAQ',
        customer_service_overview: 'Views/CustomerServiceOverview',
        default: 'Views/CustomerService',
    },
    article: {
        default: 'Views/Article',
        article_collection: 'Views/ArticleCollection',
    },
    product: {
        product: 'Views/Product',
    },
    norce_category: {
        default: 'Views/Category',
    },
    my_account: {
        default: 'Views/MyAccount',
        my_account_orders: 'Views/MyAccountOrders',
        my_account_account_settings: 'Views/MyAccountContactInfo',
    },
    storm_brand: {
        default: 'Views/Brand',
        norce_brand_index: 'Views/BrandsCollection',
    },
    norce_checkout: {
        norce_checkout: 'Views/Checkout',
        norce_order_confirmation: 'Views/OrderConfirmation',
    },
    store: {
        default: 'Views/Store',
        store_map: 'Views/Store',
    },
} as const;

const viewsWithoutMarginTop = new Set(['Views/BikeSelector']);

export type ViewsMapping = typeof viewsMapping;
export type ViewTypes = keyof ViewsMapping;
export type ViewTypeTemplates<ViewType extends ViewTypes> = keyof ViewsMapping[ViewType];
export type ViewComponentPath<
    ViewType extends ViewTypes,
    ViewTemplate extends keyof ViewsMapping[ViewType],
> = ViewsMapping[ViewType][ViewTemplate];

export interface ViewProps {
    id?: string;
    loading?: boolean;
    template: string;
    type: string;
}
const View: React.FunctionComponent<ViewProps> = ({ type, template, loading = false, id = '' }) => {
    // Map towards available view components
    const viewPath = viewsMapping[type]?.[template] || viewsMapping.page?.not_found;
    const ViewComponent = useMemo(() => loadable(() => import(`${viewPath}`)), [viewPath]);

    const pageData = useAppSelector((state) => state.page.data);
    const currency = useAppSelector((state) => state.application.site.currency);
    const applicationId = useAppSelector((state) => state.application.site.applicationId);

    debugHelper({
        context: 'View',
        message: 'Render:init',
        additionalParameters: { loading, type, template },
    });

    // Trigger page view based events on page id change
    useEffect(() => {
        Events.trigger(EVENTS.PAGE.VIEW);
        if (type === 'product') {
            Events.trigger(EVENTS.ECOMMERCE.PRODUCT.VIEW);
        }

        try {
            if (type && template) {
                setTimeout(() => {
                    AppEvents.trigger(PageEvents.VIEW, {
                        title: pageData!.title || pageData!.defaultName,
                        template,
                        type,
                        currencyCode: currency?.code,
                        applicationId,
                    });

                    if (type === 'product') {
                        AppEvents.trigger(ProductEvents.VIEW, { data: pageData!.product });
                    }
                });
            }
        } catch (e) {
            //
        }
    }, [id]);

    const customContentWrapperStyling = viewsWithoutMarginTop.has(viewPath);

    return (
        <Main>
            <StyledContentWrapper customStyling={customContentWrapperStyling}>
                <PageMeta />
                <ViewComponent opacity={loading ? '0' : '1'} />
                <CompareProductsPopup />
            </StyledContentWrapper>
        </Main>
    );
};

export default View;
